import Vue from 'vue'
import Vuex from 'vuex'

import clienti from './clienti'
import rivenditori from './rivenditori'
import services from './services'
import utenti from './utenti'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    clienti,
    rivenditori,
    services,
    utenti
  },
  state: {
    leftDrawer: false,
    rightDrawer: false,
    hideRightDrawer: false,
    rightDrawerReadOnly: false,
    token: '',
    moduliLicenza: [],
    localModuliLicenza: [],
    utente: ''
  },
  getters: {
    leftDrawer: state => state.leftDrawer,
    rightDrawer: state => state.rightDrawer,
    hideRightDrawer: state => state.hideRightDrawer,
    rightDrawerReadOnly: state => state.rightDrawerReadOnly,
    token: state => state.token,
    moduliLicenza: state => state.moduliLicenza,
    localModuliLicenza: state => state.localModuliLicenza,
    utente: state => state.utente
  },
  mutations: {
    SET_LEFT_DRAWER(state, value) {
      state.leftDrawer = value
    },
    SET_RIGHT_DRAWER(state, value) {
      state.rightDrawer = value
    },
    HIDE_RIGHT_DRAWER(state, value) {
      state.hideRightDrawer = value
    },
    SET_RIGHT_DRAWER_READ_ONLY(state, value) {
      state.rightDrawerReadOnly = value
    },
    SET_TOKEN(state, value) {
      state.token = value
    },
    SET_MODULI_LICENZA(state, value) {
      state.moduliLicenza = [...value]
      state.localModuliLicenza = [...value]
      const bb = Buffer.from(JSON.stringify(state.moduliLicenza)).toString('base64')
      sessionStorage.setItem('moduli', bb)
    },
    CLEAR_MODULI_LICENZA(state) {
      state.moduliLicenza = []
      state.localModuliLicenza = []
    }
  }
})
