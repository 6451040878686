'use strict'

const service = config.VUE_APP_service_licenze || ''

async function getConfigurazione(configurazioneId, subConf) {
  try {
    const response = await fetch(`${service}/backoffice/${configurazioneId}${subConf ? '/' + subConf : ''}`)
    return await response.json()
  } catch (error) {
    console.log(error)
    // errorService.onError(error, 'getConfigurazione')
  }
}

export default {
  getConfigurazione
}
