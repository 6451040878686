<template>
  <v-navigation-drawer
    :value="showDrawer"
    temporary
    fixed
    right
    width="500"
    :disable-route-watcher="true"
    stateless
  >
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title class="text-h6">{{drawerTitle}}</v-list-item-title>
          <v-list-item-subtitle>{{drawerSubtitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider></v-divider>
    <v-form ref="form" v-model="formFieldsValid" lazy-validation>
      <v-list dense>
        <v-list-item
          v-for="item in serviceItems"
          :key="item.label"
        >
          <v-list-item-content>
            <v-list-item-title>
              <template v-if="item.type==='Apikey'">
                <pApikey @change="onChangeApikey" :apikey="item.value"/>                
              </template>
              <template v-else>
                <component :is="'p' + item.type" :item="item" @change="componentValueChange" :disabled="readOnly"/>
              </template> 
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-form>
    <template v-slot:append>
      <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex justify-space-around" cols="12" sm="6" v-if="!readOnly">
            <v-btn color="success mb-2" @click="onSalva">Salva</v-btn>
          </v-col>
          <v-col class="d-flex justify-space-around" cols="12" sm="6">
            <v-btn color="secondary mb-s" @click="onAnnulla" >{{annullaTitle}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<script>
import * as dot from 'dot-object'
import _ from 'lodash'
import { rivenditore } from '@/mixins/rivenditore.js'
import configurazioni from '@/services/backofficeService'
import pTesto from '@/views/drawer/elementi/testo'
import pApikey from '@/views/drawer/elementi/apikey'
// import pMultiselect from '@/views/drawer/elementi/multiselect'
import pSwitch from '@/views/drawer/elementi/switch'

export default {
  components: {
    pTesto,
    pApikey,
    // pMultiselect,
    pSwitch
  },
  mixins: [
    rivenditore
  ],
  data: () => ({
    backoffice: null,
    show: false,
    locService: null,
    formFieldsValid: true,
    boServices: [
      {
        model: 'codice',
        valueField: 'codice',
        label: 'Codice',
        type: 'Testo',
        required: true,
        maxlength: 30,
        nospaces: true,
        setristretto: true
      },
      {
        model: 'descrizione',
        valueField: 'descrizione',
        label: 'Descrizione',
        type: 'Testo',
        required: true
      }]
  }),
  async mounted() {
    this.backoffice = await configurazioni.getConfigurazione('backoffice', 'pagine')
  },
  watch: {
    service(value) {
      this.locService = value
    }
  },
  computed: {
    showDrawer() {
      return this.$store.getters.rightDrawer
    },
    readOnly() {
      return this.$store.getters.rightDrawerReadOnly
    },
    drawerTitle() {
      if (this.readOnly) {
        return 'Visualizza service'
      } else {
        return this.locService && this.locService.codice ? 'Modifica service' : 'Nuovo service'
      }
    },
    drawerSubtitle() {
      return this.locService ? `${this.locService.codice} - ${this.locService.descrizione}` : ''
    },
    annullaTitle() {
      return this.readOnly ? 'Chiudi' : 'Annulla'
    },
    service() {
      return this.$store.getters['services/service'] || null // || { codice: '', descrizione: '' }
    },
    serviceItems() {
      if (!this.locService || !this.backoffice) {
        return []
      }
      const vars = this.backoffice.services
      const items = []
      for (const item of vars) {
        let itemService = { value: dot.pick(item.valueField, this.locService), ...item }
        if (item.prefix) {
          const prefix = this.getUserPrefix(item.prefix) + 'srv_' 
          itemService.prefix = prefix
          itemService.value = itemService.value.substring(prefix.length)
        }
        items.push(itemService)
      }
      return items  
    }
  },
  methods: {
    onChangeApikey(value) {
      this.locService.apikey = value
    },
    componentValueChange(data) {
      const { model, subModel, value } = data
      let tgt = {}
      if (subModel) {
        dot.str(model, value[subModel], tgt)
      } else {
        dot.str(model, value, tgt)
      }
      _.merge(this.locService, tgt)
    },
    onAnnulla() {
      this.$refs.form.reset()
      this.$store.commit('SET_RIGHT_DRAWER', false)
      this.$store.commit('services/SET_REFRESH')
    },
    onSalva() {
      const valid = this.$refs.form.validate()
      if (valid) {
        this.$store.commit('services/SET_SERVICE', this.locService)
        this.$store.commit('services/SET_SERVICE_SAVED')
      }
    }
  }
}
</script>