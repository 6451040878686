import moment from 'moment'

moment.locale('it') // in base alla lingua scelta

export function date (value) {
  return moment(value).isValid() ? moment(value).format('L') : ''
  // return moment(value).format('DD/MM/YYYY')
}

export function fullDate (value) {
  return moment(value).isValid()  ? moment(value).format('DD/MM/YYYY HH:mm') : ''
  // return moment(value).format('DD/MM/YYYY')
}

export function time (value) {
  return moment(value).isValid() ? moment(value).format('HH:mm') : ''
}

export function toProperCase(value) {
  return value.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() })
}

export function toLowerCase(value) {
  return value.toLowerCase()
}

export function euro (value) {
  return value ? value.toFixed(2) + ' €': ''
}

export function dueDecimali (value) {
  return value ? +parseFloat(value).toFixed(2): ''
}
