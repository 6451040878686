<template>
<v-app>
  <left-drawer/>
  <right-drawer/>
  <v-app-bar dense app color="#e7e5ba">
    <v-app-bar-nav-icon v-if="!usaLeft2" @click="openLeftDrawer"></v-app-bar-nav-icon>
    <v-app-bar-title>Ubisell licensing backoffice </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn icon>
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </v-app-bar>

  <!-- Sizes your content based upon application components -->
  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>
  </v-main>

  <v-footer app>
    <!-- -->
  </v-footer>
</v-app>
</template>

<script>
import LeftDrawer from './views/drawer/leftDrawer2'
import RightDrawer from './views/drawer/rightDrawer'

export default {
  name: 'App',

  components: {
    LeftDrawer,
    RightDrawer
  },

  data: () => ({
    usaLeft2: true
  }),
  methods: {
    openLeftDrawer() {
      this.$store.commit('SET_LEFT_DRAWER', true)
    }
  }
};
</script>
