import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'
import store from '../store'
import { Role } from '@/helpers/role';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/dashboard'),
    meta: {}
  },
  {
    path: '/v-clienti',
    name: 'Clienti',
    component: () => import(/* webpackChunkName: "clienti" */ '../views/clienti'),
    meta: { authorize: [Role.Admin, Role.Reseller] }
  },
  {
    path: '/v-service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "service" */ '../views/service'),
    meta: { authorize: [Role.Admin, Role.Reseller] }
  }, 
  {
    path: '/v-configurazione',
    name: 'Configurazione',
    component: () => import(/* webpackChunkName: "configurazione" */ '../views/configurazione'),
    meta: { authorize: [Role.Admin] }
  }, 
  {
    path: '/v-rivenditori',
    name: 'Rivenditori',
    component: () => import(/* webpackChunkName: "rivenditori" */ '../views/rivenditori'),
    meta: { authorize: [Role.Admin] }
  },   
  {
    path: '/pdf',
    name: 'Pdf',
    component: () => import(/* webpackChunkName: "pdf" */ '../views/pdfViewer/viewer.vue'),
    props: route => ({ source: route.params.source })
  },    
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login')
  },
  {
    path: '/register/:id',
    component: () => import(/* webpackChunkName: "register" */ '../views/register')
  },
  {
    path: '/token',
    name: 'Token',
    component: () => import(/* webpackChunkName: "token" */ '../views/licenza/tokenScaduto.vue')
  },
  {
    path: '/licenza',
    name: 'Licenza',
    component: () => import(/* webpackChunkName: "licenza" */ '../views/licenza/licenzaScaduta.vue')
  },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  const currentUser = store.getters['utenti/user']
  if (authorize) {
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return next({ path: '/login', query: { returnUrl: to.path } });
    }

    // check if route is restricted by role
    if (authorize.length && !_.intersection(authorize, currentUser.roles).length) {
        // role not authorised so redirect to home page
        return next({ path: '/' });
    }
}

  next()
})

export default router
