import _ from 'lodash'
import rivenditori from '@/services/rivenditoriService'

export const rivenditore = {
  data() {
    return {
    }
  },
  methods: {
    getUserPrefix(prefix) { // passa il nome del campo che cerca
      const utente = this.$store.getters['utenti/user']
      let prefisso = _.get(utente, prefix, 'xxxxxxxxx')
      if (prefisso === 'ubico') {
        return ''
      }
      return prefisso + '_'
    },
    getReseller() {
      const utente = this.$store.getters['utenti/user']
      return utente.company
    },
    async getIstanzaDb(codiceRivenditore) {
      const codice = codiceRivenditore || this.getReseller()
      const rivenditore = await rivenditori.getRivenditore(codice)
      return rivenditore.istanzaDb || ''
    }
  }
}