import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import * as filters from './filters'
import Clipboard from 'v-clipboard'

Vue.use(Clipboard)

Vue.config.productionTip = false

for (const key in filters) {
  Vue.filter(key, filters[key])
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// router.replace('/login')