import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    service: null,
    saved: false,
    refresh: false
  },
  getters: {
    service: state => state.service,
    saved: state => state.saved,
    refresh: state => state.refresh
  },
  mutations: {
    SET_SERVICE (state, payload) {
      state.service = payload
    },
    CLEAR_SERVICE (state) {
      state.service = null
    },
    SET_SERVICE_SAVED (state) {
      state.saved = true
    },
    CLEAR_SERVICE_SAVED (state) {
      state.saved = false
    },
    SET_REFRESH (state) {
      state.refresh = true
    },
    CLEAR_REFRESH (state) {
      state.refresh = false
    }
  },
  actions: {
  }
}
