import Vue from 'vue'
import Vuex from 'vuex'
import licenze from '@/services/licenzeService.js'

Vue.use(Vuex)

const initialState = JSON.parse(sessionStorage.getItem('user'));
// salvare refresh token su cookie

export default {
  namespaced: true,
  state: {
    user: initialState ? initialState : null,
    token: initialState ? initialState.token : null
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token
    },
    user: state => state.user
  },
  mutations: {
    // TODO: IMPORTANTE !! Rivedere tutta la gestione. Le autorizzazioni vanno prese sempre dal token, in modo che non sia manomettibile !!!
    setToken(state, token) {
      state.token = token
    },
    logout(state) {
      state.user = null
      state.token = null
/*       sessionStorage.removeItem('user')
      sessionStorage.removeItem('moduli') */
      sessionStorage.clear()
    }
  },
  actions: {
    setUser({ commit, state }, user) {
      state.user = user
      state.token = user.token
      sessionStorage.setItem('user', JSON.stringify(user))
      const moduliLicenza = user.modules.map(x => x.nome)
      const bb = Buffer.from(JSON.stringify(moduliLicenza)).toString('base64')
      sessionStorage.setItem('moduli', bb)
      commit('SET_MODULI_LICENZA', moduliLicenza, {root: true})  
    },
    async getModuli({ commit, state}) {
      const user = JSON.parse(sessionStorage.getItem('user'))
      if (user) {
        const verifica = await licenze.verifyToken(user.token)
        if (verifica.stato === 'token.scaduto') {
          commit('logout')
        } else {
          const moduliLicenza = verifica.moduli.map(x => x.nome)
          const bb = Buffer.from(JSON.stringify(moduliLicenza)).toString('base64')
          sessionStorage.setItem('moduli', bb)
          commit('SET_MODULI_LICENZA', moduliLicenza, {root: true})
        }
      }
    }
  }
}
