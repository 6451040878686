<template>
  <div>
    <v-text-field
      :label="label"
      v-model="originalValue"
      :persistent-hint="!apikey"
      :readonly="true"
      :hint="hint"
      :loading="richiestaInCorso"
      @change=onChange>
      <template v-slot:append-outer>
        <v-icon color="success" @click="onGeneraApikey" title="Genera una nuova chiave">mdi-file-key-outline</v-icon>
      </template>
    </v-text-field>
      <dialog-conferma
      :show="dialogConferma"
      title="Modifica chiave"
      :confirmText="confirmText"
      message="Attenzione! Il precedente valore non sarà più valido. Confermi Operazione ?"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false"
    />
  </div>  
</template>

<script>
  import DialogConferma from '@/components/dialogConferma'
  import licenze from '@/services/licenzeService'

  export default {
    components: {
      DialogConferma
    },
    name: 'apikey',
    props: {
      apikey: String,
      trigger: Number,
      short: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: 'Apikey'
      },
      hint: {
        type: String,
        default: 'Crea una nuova apikey'
      }
    },
    data: (vm) => ({
      richiestaInCorso: false,
      originalValue: vm && vm.apikey ? vm.apikey : '',
      dialogConferma: false,
      confirmText: 'SI'
    }),
    mounted() {
      this.originalValue = this.apikey || ''
    },
    watch: {
      showDrawer(value) {
        if (value && this.item && this.item.value) {
          this.originalValue = JSON.parse(JSON.stringify(this.item.value))
        }
      },
      trigger() {
        this.originalValue = this.apikey || ''
      }
    },
    computed: {
      showDrawer() {
        return this.$store.getters.rightDrawer
      }
    },
    methods: {
      async onGeneraApikey() {
        if (this.apikey) {
          this.dialogConferma = true
        } else {
          await this.generaApikey()
        }
      },
      async onConferma() {
        await this.generaApikey()
        this.dialogConferma = false
      },
      async generaApikey() {
        this.$emit('loading', true)
        const newcode = this.short ?  await licenze.getShortApiKey() : await licenze.getApiKey()
        this.originalValue = newcode.apikey
        this.onChange(newcode.apikey)
        this.$emit('loading', false)
      },
      async onChange(value) {
        if (value) {
          this.originalValue = value
          this.$emit('change', value)
          this.update
        }
      }
    }
  }
</script>