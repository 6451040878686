'use strict'
import Semaphore from 'semaphore-async-await'
const service = config.VUE_APP_service_logger || '' // usato solo per installazione su Windows 32bit (senza IIS con reverse proxy)

let logQueue = []
let timerQueue = null

const lock = new Semaphore(1)

async function log(log, tags, level) {
  const data = {
    log: log.message ? log.message : log,
    level: level || 'info',
    tags
  }
  logPush(data)
}

async function logError(log, tags, level) {
  const data = {
    log: log.message ? log.message : log,
    level: level || 'error',
    tags
  }
  logPush(data)
}

async function logPush(data) {
  await lock.wait()
  logQueue.push(data)
  lock.signal()

  if (!timerQueue) {
    timerQueue = setTimeout(async () => {
      console.log('sendLog')
      await sendLog()
      timerQueue = null
    }, 5000)
  }
}

async function sendLog() {
  await lock.wait()
  var data = logQueue
  logQueue = [] // dovrebbe cancellare solo se è riuscito ad inviare --> try lock.waitFor()
  lock.signal()
  console.log(data)
  /* const response =  */
  try {
    await fetch(`${service}/logger/log`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
  } catch (error) {
    console.log('Errore registrazione errori...')
  }
  // return await response.json()
}

/* async function log(log, level, tags) {
  const data = {
    type: 'log',
    log,
    level: level || 'DEBUG',
    tags
  }
  const response = await fetch('/logger/log', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
} */

/* async function logError(log, level, tags) {
  const data = {
    type: 'error',
    log,
    level: level || 'DEBUG',
    tags
  }
  const response = await fetch('/logger/error', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data)
  })
  return await response.json()
}
 */
export default {
  log,
  logError
}
