<template>
  <div>
    <drawer-clienti v-if="isClienti" />
    <drawer-rivenditori v-if="isRivenditori" />
    <drawer-service v-if="isService" />
  <!--   <drawer-articoli v-if="isArticoli || isNewArticolo" />
    
    <drawer-movimenti v-else-if="isMovimenti" /> -->
  </div>
</template>

<script>
  import DrawerClienti from '@/views/clienti/drawerClienti'
  import DrawerRivenditori from '@/views/rivenditori/drawerRivenditori'
  import DrawerService from '@/views/service/drawerServices'
/* import DrawerArticoli from '@/views/articoli/drawerArticoli'
import DrawerClienti from '@/views/clienti/drawerClienti'
import DrawerMovimenti from '@/views/movimenti/drawerMovimenti' */
// TODO: Probabilemente manca la separazione tra movimento e dettaglio movimento

export default {
  components: {
    DrawerClienti,
    DrawerRivenditori,
    DrawerService
/*     DrawerArticoli,
    DrawerClienti,
    DrawerMovimenti */
  },
  data: () => ({
  }),
  computed: {
    pagina() {
      return this.$route.name
    },
    isService() {
      return this.pagina === 'Service'
    },
    isClienti() {
      return this.pagina === 'Clienti'
    },
    isRivenditori() {
      return this.pagina === 'Rivenditori'
    },
    isMovimenti() {
      return this.pagina === 'Movimenti'
    }
/*     isNewArticolo() {
      return this.$store.getters['movimenti/creaArticolo']
    } */
  }
}
</script>
