import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    rivenditore: null,
    saved: false,
    refresh: false
  },
  getters: {
    rivenditore: state => state.rivenditore,
    saved: state => state.saved,
    refresh: state => state.refresh
  },
  mutations: {
    SET_RIVENDITORE (state, payload) {
      state.rivenditore = payload
    },
    CLEAR_RIVENDITORE (state) {
      state.rivenditore = null
    },
    SET_RIVENDITORE_SAVED (state) {
      state.saved = true
    },
    CLEAR_RIVENDITORE_SAVED (state) {
      state.saved = false
    },
    SET_REFRESH (state) {
      state.refresh = true
    },
    CLEAR_REFRESH (state) {
      state.refresh = false
    }
  },
  actions: {
  }
}
