'use strict'

import loggerService from './loggerService'

// export default class ErrorService {
// constructor() {
// ErrorService.initHandler()
// }

function onError(error, source) {
  const response = error.response
  if (response && response.status >= 400 && response.status < 405) {
    // You can handle this differently
    sentryLogEngine(error, source)
    return false
  }
  // Send Error to Log Engine e.g LogRocket
  logRocketLogEngine(error, source)
}

function onWarn(error, source) {
  // Send Error to Log Engine e.g LogRocket
  logRocketLogEngine(error, source)
}

function onInfo(error, source) {
  // You can handle this differently
  sentryLogEngine(error, source)
}

function onDebug(error, source) {
  const response = error.response
  if (response && response.status >= 400 && response.status < 405) {
    // You can handle this differently
    sentryLogEngine(error, source)
    return false
  }
  // Send Error to Log Engine e.g LogRocket
  logRocketLogEngine(error, source)
}

function initHandler() {
  // const scope = this
  window.onerror = (message, url, lineNo, columnNo, error) => {
    // console.log(error, 'test')
    if (error) {
      onError(error)
      console.log(message, url, lineNo, columnNo, error)
    }
  }
}

function displayErrorAlert(message) {
  /*     Swal.fire({
    title: 'Error!',
    text: message,
    icon: 'error'
  }) */
}

function logRocketLogEngine(error, source) {
  // Implement LogRocket Engine here
  // console.log(error, 'LogRocket')
  loggerService.logError(error, source)
}

function sentryLogEngine(error, source) {
  // Implement Sentry Engine here
  // console.log(error, 'Sentry')
  loggerService.logError(error, source)
}

export default {
  initHandler,
  onError,
  onWarn,
  onInfo,
  onDebug,
  displayErrorAlert
}
